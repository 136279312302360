/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { useContext, useEffect } from "react";
import { DocumentContext } from "../../../context/DocumentContext";
import { useKeyPress } from "../../../hooks/useKeyPress";
import classNames from "classnames";
import { Cross } from "../../../_helpers/Icons/miscellaneousIcons";

const Tip = ({
  active,
  description,
  image,
  imageXS,
  setTipActive,
  title,
  fromConsultationForm = false,
}) => {
  const { screen } = useContext(DocumentContext);

  let hasTable = false;
  let imageWidth = `100%`;

  //

  switch (title?.toLowerCase()) {
    case `table`:
      imageWidth = `33.333%`;
      break;

    case `color`:
    case `cut`:
      imageWidth = `50%`;
      break;

    case `clarity`:
    case `depth`:
    case `fluorescence`:
    case `l/w ratio`:
      imageWidth = `66.667%`;
      break;

    case `symmetry`:
      imageWidth = `83.333%`;
      break;

    case `supplier standards`:
      hasTable = true;
      imageWidth = `50%`;
      break;

    default:
      break;
  }

  //

  const escKeyPressed = useKeyPress(`Escape`);

  useEffect(() => {
    if (escKeyPressed) {
      setTipActive(false);
    }
    /* eslint-disable-next-line */
  }, [escKeyPressed]);

  //

  return (
    <div
      className={classNames(
        "w-screen fixed top-0 left-0 right-0 bottom-0 z-[60] flex items-center justify-center transform transition-all duration-500 delay-100 ease-in",
        fromConsultationForm
          ? "bg-[#110F3D] bg-opacity-60 h-[calc(100dvh)]"
          : "bg-black bg-opacity-80 h-screen",
        active
          ? "opacity-100 pointer-events-auto scale-100"
          : "opacity-0 pointer-events-none scale-105"
      )}
    >
      <div className="w-full relative">
        <div className="grid grid-cols-24 gap-x-0 relative px-0">
          <article
            className={classNames(
              "col-span-22 relative  col-start-2  flex flex-col items-center justify-center p-6 md:p-5 bg-white",
              fromConsultationForm
                ? "md:col-span-12 md:col-start-7 lg:col-span-10 lg:col-start-8 border-[1.5px] border-theme-blue min-h-[60vw] sm:min-h-[22vw]"
                : " md:col-span-14 md:col-start-6 min-h-[25vw]"
            )}
          >
            <div className="w-12 h-12 absolute top-0 right-0 z-20">
              <button
                type="button"
                className={`w-12 h-12 relative block ${
                  active ? "pointer-events-auto" : "pointer-events-none"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setTipActive(false);
                }}
              >
                <div className="w-12 h-12 relative flex items-center justify-center p-2">
                  <Cross className="w-12 h-12 relative block fill-current text-theme-blue" />
                </div>
              </button>
            </div>

            <div
              style={{
                transform: `translate3d(0, ${active ? "0" : "-6px"}, 0)`,
              }}
              className={`w-full relative flex text-center sm:text-left transition-all transform duration-500 delay-200 ease-in ${
                active ? "opacity-100" : "opacity-0"
              } ${
                !hasTable && image
                  ? "items-start justify-start"
                  : "items-center justify-center"
              } ${hasTable ? "mt-8" : ""}`}
            >
              <h3
                className={`text-theme-blue uppercase font-serif ${
                  !hasTable && image
                    ? "text-2xl leading-tight sm:text-3xl sm:leading-none md:text-4xl md:leading-none"
                    : `text-3xl leading-none sm:text-4/5xl sm:leading-tight ${
                        fromConsultationForm
                          ? "pt-6 xl:pt-0 text-3xl md:text-4xl"
                          : "md:text-6xl"
                      }  md:leading-none`
                } ${!title && "hidden"}`}
              >
                {title || ``}
              </h3>
            </div>

            <div className="w-full relative flex flex-col items-center">
              {active && (image || imageXS) && (
                <>
                  {(screen === "sm" && (
                    <img
                      style={{
                        transform: `translate3d(0, ${
                          active ? `0` : `12px`
                        }, 0)`,
                        width: "calc(100% - 3rem)",
                      }}
                      className={`transform transition-all duration-500 ease-in object-contain ${
                        active ? "opacity-100 delay-200" : "opacity-0 delay-100"
                      } ${
                        hasTable ? "mt-3 mb-6 order-1" : "mt-0 mb-0 order-none"
                      }`}
                      src={imageXS}
                      alt={`Tip ${title}`}
                    />
                  )) || (
                    <img
                      style={{
                        transform: `translate3d(0, ${
                          active ? `0` : `12px`
                        }, 0)`,
                        width: imageWidth,
                        maxHeight: "60vh",
                      }}
                      className={`transform transition-all duration-500 object-contain ${
                        active ? "opacity-100 delay-200" : "opacity-0 delay-100"
                      } ${
                        hasTable ? "mt-4 mb-8 order-1" : "mt-0 mb-0 order-none"
                      }`}
                      src={image}
                      alt={`Tip ${title}`}
                    />
                  )}
                </>
              )}

              <div
                style={{
                  transform: `translate3d(0, ${active ? `0` : `12px`}, 0)`,
                }}
                className={classNames(
                  "relative  text-theme-blue text-center transition-all transform duration-500 ease-in",
                  fromConsultationForm
                    ? "text-lg  sm:w-7/12 leading-6 lg:w-1/2"
                    : "text-sm sm:text-2xl w-5/6 md:w-1/2 leading-tight  sm:leading-none",
                  active
                    ? "opacity-100 delay-400 pointer-events-auto"
                    : "opacity-0 delay-100 pointer-events-none",
                  !hasTable && image
                    ? "mt-4 mb-8"
                    : fromConsultationForm
                    ? "mt-6 md:mt-7 pb-6 xl:pb-0"
                    : "mt-8"
                )}
                dangerouslySetInnerHTML={{ __html: description || `` }}
              />
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Tip;
