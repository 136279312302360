/* eslint-disable react/prop-types */
import React from "react";
import Footer from "../../stories/Footer";

const FooterWrapper = ({ ...props }) => {
  return (
    <div className="w-full">
      <Footer footerLinks={props?.footerLinks} />
      {/* Google Rating Banner */}
      {/* <div id="brb_collection_151657"></div>
      <script
        async
        defer
        dangerouslySetInnerHTML={{
          __html: `!function(e){var c=document.createElement("script");c.src=e,document.body.appendChild(c)}("https://shop.frankdarling.com?cf_action=brb_embed&brb_collection_id=151657&brb_callback=brb_"+(new Date).getTime());`,
        }}
      /> */}
      <script
        type="application/ld+json"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "@id": "https://frankdarling.com/about/",
            name: "Frank Darling",
            url: "https://frankdarling.com",
            address: {
              "@type": "PostalAddress",
              addressLocality: "New York, NY",
              addressCountry: "United States",
              postalCode: "10013",
              streetAddress: "50 Hudson Street, Floor 3",
            },
            logo: "https://frankdarling.com/assets/images/header/logo.svg",
            description:
              "Frank Darling provides the most accessible online custom engagment ring shopping experience. Build a unique engagement ring with our collection of Lab grown diamonds, natural diamonds and many more along with the help of our experts, for free.",
            sameAs: [
              "https://www.facebook.com/frankdarlingny/",
              "https://www.instagram.com/frankdarling/",
              "https://twitter.com/frankdarlingny/",
            ],
          }),
        }}
      />
    </div>
  );
};

export default FooterWrapper;
