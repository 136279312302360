import React from "react";
import PropTypes from "prop-types";
import Loader from "./Loader";

const Button = ({
  children,
  variant,
  isDisabled,
  onClick,
  className,
  loading,
  ...rest
}) => {
  const variants = {
    black:
      "border-white bg-black hover:bg-white hover:text-black text-white disabled:bg-white disabled:text-gray-400 disabled:border-gray-400",
    primary:
      "border-theme-blue hover:bg-theme-blue bg-white text-theme-blue hover:text-white disabled:bg-white disabled:text-gray-400 disabled:border-gray-400",
    disabled: "bg-gray-200 cursor-not-allowed text-white",
    custom: "disabled:bg-white disabled:text-gray-400 disabled:border-gray-400",
  };

  return (
    <button
      className={`w-full ${className} border font-sans text-sm xl:text-lg 3xl:text-2xl 4xl:text-3xl 4xl:leading-none transition-colors duration-300 uppercase tracking-widest focus:outline-none ${variants[variant]}  disabled:cursor-not-allowed`}
      disabled={isDisabled}
      onClick={onClick}
      {...rest}
    >
      {loading ? (
        <Loader className="text-dark-blue hover:text-white" />
      ) : (
        children
      )}
    </button>
  );
};

Button.propTypes = {
  /*
   * children of the button
   */
  children: PropTypes.node,
  /*
   * Button variant
   * (e.g. primary, black, disabled)
   */
  variant: PropTypes.string,
  /*
   * Button disabled flag
   */
  isDisabled: PropTypes.bool,
  /*
   * Button loading state
   */
  loading: PropTypes.bool,
  /*
   * Function for button onClick event
   */
  onClick: PropTypes.func.isRequired,
  /*
   * Classes of the button
   */
  className: PropTypes.string,
};

Button.defaultProps = {
  value: "Continue",
  variant: "black",
  isDisabled: false,
  loading: false,
  onClick: () => alert("Button clicked!"),
  className: "h-12 xl:h-16",
};

export default Button;
