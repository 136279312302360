/* eslint-disable @next/next/no-img-element */
import React from "react";
import PropTypes from "prop-types";
import Accordion from "./Accordion";
import { useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import queryString from "query-string";
import useDialog from "../hooks/useDialog";
import { getRakutenParams } from "../_helpers/functions";

const FooterMenuTitle = ({ title, open, showArrow = true }) => {
  return (
    <div className="text-white font-semibold text-sm lg:text-lg lg:leading-snug 4xl:text-3xl 4xl:leading-9 flex flex-row justify-between uppercase items-center cursor-text">
      <span className="text-left whitespace-nowrap">{title}</span>
      {showArrow ? (
        <img
          loading="lazy"
          className={`w-5  transform transition-all duration-500 lg:hidden ${
            open ? "rotate-0" : "-rotate-90"
          }`}
          src="/assets/images/icons/chevron-white.svg"
          alt="Expander Chevron"
        />
      ) : null}
    </div>
  );
};

FooterMenuTitle.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  showArrow: PropTypes.bool,
};

const FooterOpenMenu = ({ links }) => {
  const router = useRouter();
  const { dialogType } = useDialog();

  return (
    <ul className="pb-4 3xl:pb-0 space-y-2 lg:space-y-0 pt-6">
      {links.map((link) => {
        return (
          <li
            key={`${link.link}-${link.name}`}
            className="text-sm lg:text-lg lg:leading-snug 4xl:text-3xl 4xl:leading-9 uppercase lg:normal-case text-white"
          >
            <Link
              legacyBehavior
              href={`${link.link}${
                link.link.includes("?") &&
                (router.query?.ranEAID ||
                  router.query?.ranMID ||
                  router.query?.ranSiteID)
                  ? "&"
                  : link.link.includes("?")
                  ? ""
                  : "?"
              }${queryString.stringify({
                ...(dialogType.bottom && router.query && router.query.ranMID
                  ? getRakutenParams
                  : {}),
              })}`}
            >
              <a className="hover:underline whitespace-nowrap cursor-pointer">
                {link.name}
              </a>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

FooterOpenMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

const Footer = ({ footerLinks, email, phoneNo, socialLinks }) => {
  const router = useRouter();
  const { dialogType } = useDialog();

  const [openIndexes, setOpenIndexes] = useState(
    footerLinks?.map((links) => false)
  );

  return (
    <div className="bg-theme-blue text-white flex flex-col lg:pt-14 3xl:pt-16 lg:pb-24 relative overflow-hidden">
      <div className="bg-theme-blue text-white flex flex-col lg:flex-row relative overflow-hidden mx-vw-1/12">
        <div className="flex flex-col space-y-5 pt-14 pb-16 lg:py-0 lg:ml-auto lg:order-last flex-shrink">
          <FooterMenuTitle title={`LET'S CHAT`} showArrow={false} />
          <Link
            legacyBehavior
            href={`/consultation?${queryString.stringify({
              ...(dialogType.bottom && router.query && router.query.ranMID
                ? getRakutenParams
                : {}),
            })}`}
          >
            <a
              target="_blank"
              rel="noreferrer"
              className="border border-white xl:text-lg text-base p-2 flex justify-between items-center"
            >
              <span className="xl:mr-24 mr-4">Book an Appointment</span>
              <img
                loading="lazy"
                src="/assets/images/footer/arrow.svg"
                className="h-2"
                alt="arror"
              />
            </a>
          </Link>
        </div>
        <div className="flex flex-col lg:flex-row flex-1 lg:flex-none">
          {footerLinks.map((links, index) => {
            return (
              <Accordion
                links={links}
                title={
                  <FooterMenuTitle
                    title={links.title}
                    open={openIndexes[index]}
                  />
                }
                data={<FooterOpenMenu links={links.links} />}
                open={openIndexes[index]}
                onClick={() =>
                  setOpenIndexes(
                    openIndexes.map((status, i) =>
                      i == index ? !status : status
                    )
                  )
                }
                className={`block bg-theme-blue py-4 lg:py-0 first:border-t border-b border-white lg:pl-0 lg:pr-7 xl:pr-10 lg:border-none flex-1 ${
                  links.className ?? ""
                }`}
                key={links.title}
              />
            );
          })}
        </div>
      </div>
      <div className="flex mx-vw-1/12 lg:mt-24 justify-between items-start pt-5 lg:border-t lg:flex-row flex-col lg:space-y-0 space-y-5">
        <div className="lg:text-lg 4xl:text-3xl leading-4	font-medium">
          We&apos;re available by text and chat
          <br /> every day, 10 a.m.- 6 p.m. ET.
        </div>
        <div className="lg:flex-row lg:space-x-8 lg:space-y-0 space-y-5 flex flex-col">
          <a
            className="lg:text-lg 4xl:text-3xl"
            href="mailto:hello@frankdarling.com"
          >
            hello@frankdarling.com
          </a>
          <a className="lg:text-lg 4xl:text-3xl" href="tel:+16468590718">
            (646) 859-0718
          </a>
          <div className="text-sm flex flex-col flex-shrink lg:pt-0 pt-8">
            <div className="flex-row items-start lg:justify-center space-x-3 flex">
              <a
                className="relative block"
                href="https://www.facebook.com/frankdarlingny/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  className="w-100 relative block"
                  src="/assets/images/icons/facebook.svg"
                  alt="Facebook"
                />
              </a>
              <a
                className="relative block"
                href="https://www.instagram.com/frankdarling/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  className="w-100 relative block"
                  src="/assets/images/icons/instagram.svg"
                  alt="Instagram"
                />
              </a>
              <a
                className="relative block"
                href="https://twitter.com/frankdarlingny/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  className="w-100 relative block"
                  src="/assets/images/icons/twitter.svg"
                  alt="Twitter"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-14 mx-vw-1/12">
        <div className="relative flex-1">
          <img
            loading="lazy"
            className="relative block"
            src="/assets/images/icons/frank_darling.svg"
            alt="FRANK DARLING"
          />
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  /**
   * Footer main links
   */
  footerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Title for the links
       */
      title: PropTypes.string.isRequired,
      /**
       * Sub links
       */
      links: PropTypes.arrayOf(
        PropTypes.shape({
          /**
           * Sub link text
           */
          name: PropTypes.string.isRequired,
          /**
           * Sub link href
           */
          link: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  /**
   * Email id
   */
  email: PropTypes.string,
  /**
   * Phone number
   */
  phoneNo: PropTypes.string,
  /**
   * Social links with icons
   */
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Socail Icon links
       */
      icons: PropTypes.string,
      /**
       * Href for socail link
       */
      href: PropTypes.string,
    })
  ),
};

export default Footer;
