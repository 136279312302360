import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import queryString from "query-string";
import useDialog from "../hooks/useDialog";
import { getRakutenParams } from "../_helpers/functions";

const AnimatedLink = ({
  href,
  cta,
  color,
  className,
  clickable,
  id,
  newTab,
  fontClass,
  query,
}) => {
  const router = useRouter();
  const { dialogType } = useDialog();
  const colorCode = color == "white" ? "after:bg-white" : "after:bg-theme-blue";

  const body = (
    <div
      className={`relative inline-display no-overflow  animated-link ${colorCode} cursor-pointer ${className}`}
    >
      <div
        id={id}
        className={`font-sans-serif tracking-wide leading-relaxed text-center uppercase text-${color} ${fontClass}`}
      >
        {cta}
      </div>
    </div>
  );

  if (href) {
    const rakuten = queryString.stringify({
      ...(dialogType.bottom && router.query && router.query.ranMID
        ? getRakutenParams
        : {}),
    });

    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        // adding query so that in some cases '?' doesn't show up after a param
        href={`${href}${query ? "?" + query + "&" + rakuten : "?" + rakuten}`}
        target={newTab ? "_blank" : ""}
        rel={newTab ? "noreferrer" : ""}
        className={`block ${!clickable ? "pointer-events-none" : ""}`}
      >
        {body}
      </a>
    );
  } else {
    return (
      <div className={`block ${!clickable ? "pointer-events-none" : ""}`}>
        {body}
      </div>
    );
  }
};

AnimatedLink.defaultProps = {
  color: "theme-blue",
  clickable: true,
  fontClass: "text-sm lg:text-lg",
};

AnimatedLink.propTypes = {
  /* CTA text */
  cta: PropTypes.string.isRequired,
  /* CTA Link */
  href: PropTypes.string,
  /* Tailwind color code */
  color: PropTypes.string,
  /* additional classes */
  className: PropTypes.string,
  /**
   * If link is clickable
   */
  clickable: PropTypes.bool,
  /**
   * Element ID
   */
  id: PropTypes.string,
  /**
   * To open link in new tab
   */
  newTab: PropTypes.bool,
  /**
   * Add query params to the url
   */
  query: PropTypes.string,
};

export default AnimatedLink;
