import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "../hooks/useWindowSize";
import classNames from "classnames";

const collapseSection = (element) => {
  const sectionHeight = element.scrollHeight;

  // temporarily disable all css transitions
  const elementTransition = element.style.transition;
  element.style.transition = "";

  requestAnimationFrame(function () {
    element.style.height = sectionHeight + "px";
    element.style.transition = elementTransition;

    requestAnimationFrame(function () {
      element.style.height = 0 + "px";
    });
  });

  element.removeEventListener("transitionend", () => {
    element.style.height = 0 + "px";
  });

  element.setAttribute("data-collapsed", "true");
};

const expandSection = (element) => {
  const sectionHeight = element.scrollHeight;
  element.style.height = sectionHeight + "px";

  const removeHeight = () => {
    // adding a check to avoid opening the section once it's already closed
    if (element.getAttribute("data-collapsed") != "true")
      element.style.height = null;
  };

  element.addEventListener("transitionend", removeHeight);
  element.setAttribute("data-collapsed", "false");
};

const Accordion = ({
  title,
  data,
  open,
  onClick,
  className,
  fromVault,
  isMobileOnly,
  ...rest
}) => {
  // creating a ref to make the transition smooth
  const accordionRef = useRef(null);
  const { width } = useWindowSize();

  useEffect(() => {
    if (open || (isMobileOnly && width > 1024)) {
      expandSection(accordionRef.current);
    } else {
      collapseSection(accordionRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, width]);

  return (
    <div className={className} {...rest}>
      <button
        type="button"
        onClick={() => onClick()}
        className={classNames(
          "w-full focus:outline-none",
          fromVault ? "text-lg" : ""
        )}
      >
        {title}
      </button>
      <div
        // open ? "max-h-fit" : `max-h-0 ${isMobileOnly ? "lg:max-h-full" : ""
        ref={accordionRef}
        className={classNames(
          "relative overflow-hidden transition-all duration-500",
          fromVault
            ? `whitespace-pre-wrap leading-6 text-lg ${open && "my-4"}`
            : ""
        )}
      >
        {data}
      </div>
    </div>
  );
};

Accordion.defaultProps = {
  isMobileOnly: true,
};

Accordion.propTypes = {
  /* Title node*/
  title: PropTypes.node.isRequired,
  /* Expansion node */
  data: PropTypes.node.isRequired,
  /** Is accordian open */
  open: PropTypes.bool,
  /** Onclick function */
  onClick: PropTypes.func,
  /** Wrapper classname */
  className: PropTypes.string,
  /* If accordian will work only in mobile(i.e below 1024px ) */
  isMobileOnly: PropTypes.bool,
};

export default Accordion;
